import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from "@elastic/eui";
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Recording/Position_Schedule/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import ScheduleTable from 'components/Web_User_Interface/1440p_Series/Recording/Position_Schedule/positionSheduleTable';
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "Recording Menu // Position Schedule",
  "path": "/Web_User_Interface/1440p_Series/Recording/Position_Schedule/",
  "dateChanged": "2024-09-19",
  "author": "Mike Polinowski",
  "excerpt": "Set a schedule for for your cameras pan, tilt and zoom position for days of the week and time of the day. Save camera positions from the menu above the live video and use the schedule to automate them.",
  "image": "./WebUI_1440p_SearchThumb_Features_PTZ_Tour.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title="Recording Menu // Position Schedule" dateChanged="2024-09-19" author="Mike Polinowski" tag="INSTAR IP Camera" description="Set a schedule for for your cameras pan, tilt and zoom position for days of the week and time of the day. Save camera positions from the menu above the live video and use the schedule to automate them." image='/images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_PTZ_Tour.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Web_User_Interface/1440p_Serie/Aufnahme_Aufgaben/Position_Zeitplan/" locationFR="/fr/Web_User_Interface/1440p_Series/Recording/Position_Schedule/" crumbLabel="Position Schedule" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "task-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#task-menu",
        "aria-label": "task menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Task Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "configure-a-camera-ptz-position-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-a-camera-ptz-position-schedule",
        "aria-label": "configure a camera ptz position schedule permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure a Camera PTZ Position Schedule`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e1d455b5a51bf346e54bc9df31090754/47aef/1440p_Settings_Task_Pos_Schedule.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACVklEQVQoz22RS08TYRSG+ReyJF56G4jRXlTaTufWznS+dqbXaS1FCBLiXSKCiATEmMgCAxGjEoMiGkWjCzEaF7IwrlzIX3qU8dKNiyfnrJ687zldkiQh9fYSChzguBjGnthEO/8Q/cIambOP/ot5aQ17/DF9CZW8maWnp4fu7n3+7IpEIkhSL8GD+8m4I7RvbtGY2aB54xn16xtUrz31qe0x/ZuTs5sMLrwmqbtk0jJ9vYeRIn2Ew9JfoUQgECB5IkGjUqBeEnhl4e9eyabm5qk5FmWRpWQbuIU8papHwXRRZANVMdDUHPHYMbr8yn+FqRSOW6JQdHBLZepeg/bgKQbagzRPthAFBytvY+XzCCEYqDSxTRVV0ZDTOtGjid8J9/CFySTFYtGnXC5Tr9dptVo+A+02ti3IGgY5w8AyLc6fLtKoZZBTChlZ7yT8J+xP4hRcHMfxhdVqlbrn4dVrtLwyjjDJChtNCPKOw+UxC1ekUWSFrKYRjyf+CKUIgUMB0moab9SjWqn4sj1qlTLV5hDu2DxieIozWcGMlmPUtFmdOkejoKFmZHKGRiwW7yQMBoMciR0hZaXIZU2/kpUX6HoWq9RifOE+cw/e8GZpmU+TV1ifnuH9yl0uVizSsoym6ESj8c4Nw+EwwUCA4MEgqf4MqaSKrmgMVmw2Fq/yffsJb59vsPvjKzvbW+x++cDn9XvMD1nkDJWMrBGLJjoJQ6EQoXDI/3iyX8HUdEaqJvNjFV4tTvBt+wU7H9+xenuOlflZXi/f4eXSLSY9A6+UI51Sif4S/gSOvoQmRg5/cQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1d455b5a51bf346e54bc9df31090754/e4706/1440p_Settings_Task_Pos_Schedule.avif 230w", "/en/static/e1d455b5a51bf346e54bc9df31090754/d1af7/1440p_Settings_Task_Pos_Schedule.avif 460w", "/en/static/e1d455b5a51bf346e54bc9df31090754/7f308/1440p_Settings_Task_Pos_Schedule.avif 920w", "/en/static/e1d455b5a51bf346e54bc9df31090754/29832/1440p_Settings_Task_Pos_Schedule.avif 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e1d455b5a51bf346e54bc9df31090754/a0b58/1440p_Settings_Task_Pos_Schedule.webp 230w", "/en/static/e1d455b5a51bf346e54bc9df31090754/bc10c/1440p_Settings_Task_Pos_Schedule.webp 460w", "/en/static/e1d455b5a51bf346e54bc9df31090754/966d8/1440p_Settings_Task_Pos_Schedule.webp 920w", "/en/static/e1d455b5a51bf346e54bc9df31090754/0ca5b/1440p_Settings_Task_Pos_Schedule.webp 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e1d455b5a51bf346e54bc9df31090754/81c8e/1440p_Settings_Task_Pos_Schedule.png 230w", "/en/static/e1d455b5a51bf346e54bc9df31090754/08a84/1440p_Settings_Task_Pos_Schedule.png 460w", "/en/static/e1d455b5a51bf346e54bc9df31090754/c0255/1440p_Settings_Task_Pos_Schedule.png 920w", "/en/static/e1d455b5a51bf346e54bc9df31090754/47aef/1440p_Settings_Task_Pos_Schedule.png 1063w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e1d455b5a51bf346e54bc9df31090754/c0255/1440p_Settings_Task_Pos_Schedule.png",
              "alt": "Web User Interface - 1440p Series - Recording",
              "title": "Web User Interface - 1440p Series - Recording",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ScheduleTable mdxType="ScheduleTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      